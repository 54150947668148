import axios from "axios";
import { getTokenRedirect, signOut } from "./authentication/authRedirect";
import { tokenRequest } from "./authentication/authConfig";
import store from "./store";

const http = axios.create();

http.interceptors.request.use(async (req) => {
  if (store.getters["user/isPresenter"]) {
    req.baseURL = process.env.VUE_APP_DISPLAY_API_BASE_URL;
    req.headers.Authorization = store.getters["user/presenterKey"];
    req.headers["X-AccountId"] = store.getters["user/accountId"];
  } else {
    req.baseURL = process.env.VUE_APP_DASHBOARD_API_BASE_URL;
    req.headers.Authorization = "Bearer " + (await getTokenRedirect(tokenRequest));
  }

  if (req.data) req.headers["Content-Type"] = "application/json";

  return req;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      signOut();
    }
    throw error;
  },
);

export default http;
