<script>
import { VAutocomplete } from "vuetify/lib";

export default {
  name: "WxAutocomplete",
  extends: VAutocomplete,
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-autocomplete__content {
  &.v-menu__content {
    background-color: var(--color-element-layer2);
  }

  .v-list {
    &.v-select-list {
      background-color: transparent;
    }
  }
}

.v-autocomplete {
  /**
  * Filter styled field
  * Wx Design request no border-bottom
  */
  &.filter-style {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }

    // if :dense="false"
    &:not(.v-input--dense) {
      .v-input__control {
        .v-input__append-inner {
          margin-top: 8px;
        }
      }
    }

    :hover {
      cursor: pointer;
    }
  }

  // if :dense="false"
  &:not(.v-input--dense) {
    .v-input__control {
      .v-input__append-inner {
        margin-top: 8px;
      }
    }
  }
}
</style>
