import http from "../../BaseServiceHttp";

class RejectReasonService {
  getRejectReasonById(rejectReasonId) {
    return http.get(`/reject-reasons/${rejectReasonId}`);
  }
  getRejectReasonTree(factoryId) {
    return http.get(`/factories/${factoryId}/reject-reasons-tree`);
  }
  createCategory(factoryId, newCategory) {
    return http.post(`/factories/${factoryId}/reject-reason-categories`, newCategory);
  }
  updateCategory(categoryId, updatedCategory) {
    return http.put(`/reject-reason-categories/${categoryId}`, updatedCategory);
  }
  createReason(factoryId, newReason) {
    return http.post(`/factories/${factoryId}/reject-reasons`, newReason);
  }
  updateReason(reasonId, updatedReason) {
    return http.put(`/reject-reasons/${reasonId}`, updatedReason);
  }
  deleteCategory(rejectCategoryId) {
    return http.delete(`/reject-reason-categories/${rejectCategoryId}`);
  }
  deleteReason(rejectReasonId) {
    return http.delete(`/reject-reasons/${rejectReasonId}`);
  }
  getAvailableRejectReasons(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/available-reject-reasons-flatten-tree`);
  }
  // Utility functions
  extractReasonsFromTree(rejectReasonTree, destinationList) {
    rejectReasonTree.forEach((element) => {
      if (element.type === "category") {
        if (element.children.length > 0) {
          this.extractReasonsFromTree(element.children, destinationList);
        }
      } else {
        if (element.planned) {
          destinationList.push(element);
        }
      }
    });
  }
}

export default new RejectReasonService();
