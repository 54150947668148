import http from "@/BaseServiceHttp";

class TimeRangeService {
  getTimeRangeForDate(productionUnitId, date) {
    const startDate = encodeURIComponent(date);
    return http.get(`production-units/${productionUnitId}/time-ranges/business_day?start_date=${startDate}`);
  }
}

export default new TimeRangeService();
