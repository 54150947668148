import http from "../../../BaseServiceHttp";

class ProductionRunService {
  createProductionRun(productionUnitId, productionRunCreation) {
    const forwardedPUs = productionRunCreation.forwarded_production_unit_ids
      ? `&forwarded_production_unit_ids=${productionRunCreation.forwarded_production_unit_ids}`
      : "";
    const forwardedProductionUnitGroup = productionRunCreation.forwarded_production_unit_group;
    delete productionRunCreation.forwarded_production_unit_ids;
    delete productionRunCreation.forwarded_production_unit_group;
    return http.post(
      `/production-units/${productionUnitId}/production-runs?forward_to_production_unit_group=${forwardedProductionUnitGroup}${forwardedPUs}`,
      productionRunCreation,
    );
  }
  deleteProductionRun(
    productionUnitId,
    productionUnitEventId,
    forwarded_production_unit_ids,
    forwarded_production_unit_group,
  ) {
    const forwardedPUs = forwarded_production_unit_ids
      ? `&forwarded_production_unit_ids=${forwarded_production_unit_ids}`
      : "";
    return http.delete(
      `/production-units/${productionUnitId}/production-runs/${productionUnitEventId}?forward_to_production_unit_group=${forwarded_production_unit_group}${forwardedPUs}`,
    );
  }
  updateProductionRun(productionUnitId, productionUnitEventId, productionRunUpdate) {
    const forwardedPUs = productionRunUpdate.forwarded_production_unit_ids
      ? `&forwarded_production_unit_ids=${productionRunUpdate.forwarded_production_unit_ids}`
      : "";
    const forwardedProductionUnitGroup = productionRunUpdate.forwarded_production_unit_group;
    delete productionRunUpdate.forwarded_production_unit_ids;
    delete productionRunUpdate.forwarded_production_unit_group;
    return http.put(
      `/production-units/${productionUnitId}/production-runs/${productionUnitEventId}?forward_to_production_unit_group=${forwardedProductionUnitGroup}${forwardedPUs}`,
      productionRunUpdate,
    );
  }
  getForwardedProductionUnits(activeProductionUnit, activeFactoryProductionUnits) {
    const selectedPUs = [activeProductionUnit];
    const activeProductionUnitGroupId = activeProductionUnit.production_unit_group_id;
    if (activeProductionUnitGroupId) {
      const pusInSameGroup = activeFactoryProductionUnits.filter((pu) => {
        return pu.productionUnitGroupId === activeProductionUnitGroupId && pu.id !== activeProductionUnit.id
      });
      selectedPUs.push(...pusInSameGroup);
    }
    return selectedPUs;
  }
  toForwardedProductionUnitIdsAsCsv(activeProductionUnit, forwardedProductionUnits) {
    return forwardedProductionUnits
      .filter((pu) => pu.id !== activeProductionUnit.id)
      .map((pu) => pu.id)
      .join(",");
  }
  createConversionFactorOverride(productionUnitId, payload) {
    return http.post(`/production-units/${productionUnitId}/conversion-factor-overrides`, payload);
  }
  updateConversionFactorOverride(productionUnitId, eventId, update) {
    return http.put(`/production-units/${productionUnitId}/conversion-factor-overrides/${eventId}`, update);
  }
  deleteConversionFactorOverride(productionUnitId, eventId) {
    return http.delete(`/production-units/${productionUnitId}/conversion-factor-overrides/${eventId}`);
  }
}

export default new ProductionRunService();
