import http from "@/BaseServiceHttp";

class DataSourceService {
  getDataSources(factoryId) {
    return http.get(`/factories/${factoryId}/data-sources`);
  }

  getDataSourcesActivity(deviceId) {
    return http.get(`/devices/${deviceId}/data-sources/activity`);
  }
}

export default new DataSourceService();
