import http from "../../BaseServiceHttp";

class OverviewService {
  getProductionUnitsCurrentState(factoryId) {
    return http.get(`factories/${factoryId}/production-units/current-state`);
  }

  getProductionUnitOverviewDetails(productionUnitId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    return http.get(
      `/production-units/${productionUnitId}/metrics/pu-details?start_time=${startParam}&end_time=${endParam}`,
    );
  }
  getProductionUnitsSpeed(factoryId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    return http.get(`factories/${factoryId}/production-units/speed?start_time=${startParam}&end_time=${endParam}`);
  }

  getProductionUnitsCoverage(factoryId, productionUnitIds, startTime, endTime) {
    let puIdsParam;
    if (productionUnitIds instanceof Array) {
      puIdsParam = productionUnitIds.join(",");
    } else {
      puIdsParam = productionUnitIds;
    }
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    const queryParams = `start_time=${startParam}&end_time=${endParam}&production_unit_ids=${puIdsParam}`;
    return http.get(`factories/${factoryId}/production-units/coverage?${queryParams}`);
  }
}

export default new OverviewService();
