import http from "../../BaseServiceHttp";

class TimelineService {
  getBusinessDayRange(productionUnitId, startDateStr, endDateStr) {
    const startDateURI = encodeURIComponent(startDateStr);
    const endDateURI = encodeURIComponent(endDateStr);
    return http.get(`production-units/${productionUnitId}/time-ranges/business_day_range?start_date=${startDateURI}&end_date=${endDateURI}`);
  }

  getTimelineElements(productionUnitId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    return http.get(
      `/production-units/${productionUnitId}/timeline-elements?start_time=${startParam}&end_time=${endParam}`,
    );
  }

  getProductionUnitCoverage(factoryId, productionUnitId, startTime, endTime) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    return http.get(
      `factories/${factoryId}/production-units/coverage?start_time=${startParam}&end_time=${endParam}&production_unit_ids=${productionUnitId}`
    )
  }
}

export default new TimelineService();
