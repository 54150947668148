import PackageFeatures from "@/components/PackageFeatures";

class Tiles {
  static availabilityTileName = "availability";
  static performanceTileName = "performance";
  static oeeTileName = "oee";
  static allProductsQuantityTileName = "all-products-quantity";
  static currentProductQuantityTileName = "current-product-quantity";
  static productionTileName = "production";
  static currentProductThroughputTileName = "product-throughput";
  static currentProductThroughputPerMinuteTileName = "product-throughput-per-minute";
  static currentProductThroughputPerSecondTileName = "product-throughput-per-second";
  static currentProductSpeed5mTileName = "product-speed-5m";
  static currentProductSpeed5mPerMinuteTileName = "product-speed-5m-per-minute";
  static currentProductSpeed5mPerSecondTileName = "product-speed-5m-per-second";
  static totalUptimeTileName = "total-uptime";
  static totalDowntimeTileName = "total-downtime";
  static productGiveawayTileName = "product-giveaway";
  static productGiveawayPercentTileName = "product-giveaway-percent";
  static productAverageGiveawayTileName = "product-average-giveaway";
  static productSamplingTileName = "product-sampling";
  static inProgressDowntimeTileName = "in-progress-downtime";
  static workOrderTileName = "work-order";
  static lotTileName = "lot";
  static qualityTileName = "quality";
  static rejectQuantityTileName = "reject-quantity";
  static cycleTimeTileName = "cycle-time";
  static cycleQuantityTileName = "cycle-quantity";
  static ooeTileName = "ooe";
  static timeToCompletionTileName = "time-to-completion";
  static totalWeightTileName = "total-weight";
  static totalVolumeTileName = "total-volume";
  static totalLengthTileName = "total-length";
  static unknownTileName = "unknown";

  availability = {
    name: Tiles.availabilityTileName,
    requiredFeature: PackageFeatures.availability,
  };
  allProductsQuantity = {
    name: Tiles.allProductsQuantityTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  currentProductQuantity = {
    name: Tiles.currentProductQuantityTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  performance = {
    name: Tiles.performanceTileName,
    requiredFeature: PackageFeatures.performance,
  };
  oee = {
    name: Tiles.oeeTileName,
    requiredFeature: PackageFeatures.oee,
  };
  currentProductThroughput = {
    name: Tiles.currentProductThroughputTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductThroughputPerMinute = {
    name: Tiles.currentProductThroughputPerMinuteTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductThroughputPerSecond = {
    name: Tiles.currentProductThroughputPerSecondTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductSpeed5m = {
    name: Tiles.currentProductSpeed5mTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductSpeed5mPerMinute = {
    name: Tiles.currentProductSpeed5mPerMinuteTileName,
    requiredFeature: PackageFeatures.performance,
  };
  currentProductSpeed5mPerSecond = {
    name: Tiles.currentProductSpeed5mPerSecondTileName,
    requiredFeature: PackageFeatures.performance,
  };
  totalUptime = {
    name: Tiles.totalUptimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  totalDowntime = {
    name: Tiles.totalDowntimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  productGiveaway = {
    name: Tiles.productGiveawayTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productGiveawayPercent = {
    name: Tiles.productGiveawayPercentTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productAverageGiveaway = {
    name: Tiles.productAverageGiveawayTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  productSampling = {
    name: Tiles.productSamplingTileName,
    requiredFeature: PackageFeatures.giveaway,
  };
  workOrder = {
    name: Tiles.workOrderTileName,
    requiredFeature: PackageFeatures.availability,
  };
  lot = {
    name: Tiles.lotTileName,
    requiredFeature: PackageFeatures.availability,
  };
  production = {
    name: Tiles.productionTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  quality = {
    name: Tiles.qualityTileName,
    requiredFeature: PackageFeatures.oee, // The `tile` is available to anybody
  };
  rejectQuantity = {
    name: Tiles.rejectQuantityTileName,
    requiredFeature: PackageFeatures.reject,
  };
  cycleTime = {
    name: Tiles.cycleTimeTileName,
    requiredFeature: PackageFeatures.availability,
  };
  cycleQuantity = {
    name: Tiles.cycleQuantityTileName,
    requiredFeature: PackageFeatures.availability,
  };
  ooe = {
    name: Tiles.ooeTileName,
    requiredFeature: PackageFeatures.oee,
  };
  timeToCompletion = {
    name: Tiles.timeToCompletionTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  totalWeight = {
    name: Tiles.totalWeightTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  totalVolume = {
    name: Tiles.totalVolumeTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  totalLength = {
    name: Tiles.totalLengthTileName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  unknown = {
    name: Tiles.unknownTileName,
    requiredFeature: PackageFeatures.oee, // The `tile` is available to anybody
  };
  inProgressDowntime = {
    name: Tiles.inProgressDowntimeTileName,
    requiredFeature: PackageFeatures.availability,
  };

  getGiveawayTiles() {
    return [this.productGiveaway, this.productGiveawayPercent, this.productAverageGiveaway];
  }
}

export default new Tiles();

export const inProgressDowntimeTileName = "in-progress-downtime";

export const tileKeyByName = new Map();
tileKeyByName[Tiles.availabilityTileName] = "availability";
tileKeyByName[Tiles.performanceTileName] = "performance";
tileKeyByName[Tiles.oeeTileName] = "oee";
tileKeyByName[Tiles.allProductsQuantityTileName] = "product_quantity";
tileKeyByName[Tiles.currentProductQuantityTileName] = "current_product_quantity";
tileKeyByName[Tiles.currentProductThroughputTileName] = "product_throughput";
tileKeyByName[Tiles.currentProductThroughputPerMinuteTileName] = "product_throughput_per_minute";
tileKeyByName[Tiles.currentProductThroughputPerSecondTileName] = "product_throughput_per_second";
tileKeyByName[Tiles.currentProductSpeed5mTileName] = "product_speed_5m";
tileKeyByName[Tiles.currentProductSpeed5mPerMinuteTileName] = "product_speed_5m_per_minute";
tileKeyByName[Tiles.currentProductSpeed5mPerSecondTileName] = "product_speed_5m_per_second";
tileKeyByName[Tiles.totalUptimeTileName] = "total_uptime";
tileKeyByName[Tiles.totalDowntimeTileName] = "total_downtime";
tileKeyByName[Tiles.productGiveawayTileName] = "product_giveaway";
tileKeyByName[Tiles.productGiveawayPercentTileName] = "product_giveaway_percent";
tileKeyByName[Tiles.inProgressDowntimeTileName] = "in_progress_downtime";
tileKeyByName[Tiles.productAverageGiveawayTileName] = "product_average_giveaway";
tileKeyByName[Tiles.productSamplingTileName] = "product_sampling";
tileKeyByName[Tiles.workOrderTileName] = "work_order";
tileKeyByName[Tiles.lotTileName] = "lot";
tileKeyByName[Tiles.productionTileName] = "production";
tileKeyByName[Tiles.qualityTileName] = "quality";
tileKeyByName[Tiles.rejectQuantityTileName] = "reject_quantity";
tileKeyByName[Tiles.cycleTimeTileName] = "cycle_time";
tileKeyByName[Tiles.cycleQuantityTileName] = "cycle_quantity";
tileKeyByName[Tiles.ooeTileName] = "ooe";
tileKeyByName[Tiles.timeToCompletionTileName] = "time_to_completion";
tileKeyByName[Tiles.totalWeightTileName] = "total_weight";
tileKeyByName[Tiles.totalVolumeTileName] = "total_volume";
tileKeyByName[Tiles.totalLengthTileName] = "total_length";

export const tileNameByKey = new Map();
tileNameByKey["availability"] = Tiles.availabilityTileName;
tileNameByKey["performance"] = Tiles.performanceTileName;
tileNameByKey["oee"] = Tiles.oeeTileName;
tileNameByKey["product_quantity"] = Tiles.allProductsQuantityTileName;
tileNameByKey["current_product_quantity"] = Tiles.currentProductQuantityTileName;
tileNameByKey["product_throughput"] = Tiles.currentProductThroughputTileName;
tileNameByKey["product_throughput_per_minute"] = Tiles.currentProductThroughputPerMinuteTileName;
tileNameByKey["product_throughput_per_second"] = Tiles.currentProductThroughputPerSecondTileName;
tileNameByKey["product_speed_5m"] = Tiles.currentProductSpeed5mTileName;
tileNameByKey["product_speed_5m_per_minute"] = Tiles.currentProductSpeed5mPerMinuteTileName;
tileNameByKey["product_speed_5m_per_second"] = Tiles.currentProductSpeed5mPerSecondTileName;
tileNameByKey["total_uptime"] = Tiles.totalUptimeTileName;
tileNameByKey["total_downtime"] = Tiles.totalDowntimeTileName;
tileNameByKey["product_giveaway"] = Tiles.productGiveawayTileName;
tileNameByKey["product_giveaway_percent"] = Tiles.productGiveawayPercentTileName;
tileNameByKey["in_progress_downtime"] = Tiles.inProgressDowntimeTileName;
tileNameByKey["product_average_giveaway"] = Tiles.productAverageGiveawayTileName;
tileNameByKey["product_sampling"] = Tiles.productSamplingTileName;
tileNameByKey["work_order"] = Tiles.workOrderTileName;
tileNameByKey["lot"] = Tiles.lotTileName;
tileNameByKey["production"] = Tiles.productionTileName;
tileNameByKey["quality"] = Tiles.qualityTileName;
tileNameByKey["reject_quantity"] = Tiles.rejectQuantityTileName;
tileNameByKey["cycle_time"] = Tiles.cycleTimeTileName;
tileNameByKey["cycle_quantity"] = Tiles.cycleQuantityTileName;
tileNameByKey["ooe"] = Tiles.ooeTileName;
tileNameByKey["time_to_completion"] = Tiles.timeToCompletionTileName;
tileNameByKey["total_weight"] = Tiles.totalWeightTileName;
tileNameByKey["total_volume"] = Tiles.totalVolumeTileName;
tileNameByKey["total_length"] = Tiles.totalLengthTileName;

export function getTileByName(tileName) {
  switch (tileName) {
    case "product_throughput_per_minute":
    case "product_throughput_per_second":
      return "product_throughput";
    case "product_speed_5m_per_minute":
    case "product_speed_5m_per_second":
      return "product_speed_5m";
    case "current_product_quantity":
      return "product_quantity";
    default:
      return tileName;
  }
}

export function getTileName(tileKey, tileConfig) {
  switch (tileKey) {
    case "product_quantity": {
      if (tileConfig.scope === "current_product") {
        return tileNameByKey["current_product_quantity"];
      } else {
        return tileNameByKey["product_quantity"];
      }
    }
    case "product_throughput": {
      if (tileConfig.time_unit === "minute") {
        return tileNameByKey["product_throughput_per_minute"];
      } else if (tileConfig.time_unit === "second") {
        return tileNameByKey["product_throughput_per_second"];
      } else {
        return tileNameByKey["product_throughput"];
      }
    }
    case "product_speed_5m": {
      if (tileConfig.time_unit === "minute") {
        return tileNameByKey["product_speed_5m_per_minute"];
      } else if (tileConfig.time_unit === "second") {
        return tileNameByKey["product_speed_5m_per_second"];
      } else {
        return tileNameByKey["product_speed_5m"];
      }
    }
  }

  return tileNameByKey[tileKey] ?? Tiles.unknownTileName;
}
