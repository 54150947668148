import http from "@/BaseServiceHttp";

class DeviceService {
  getDevices(factoryId) {
    return http.get(`/factories/${factoryId}/devices`);
  }
  getWiFiSignalStrength(factoryId, startDate, endDate) {
    return http.get(`/factories/${factoryId}/devices/wifi-signal-strength?startDate=${startDate}&endDate=${endDate}`);
  }
}

export default new DeviceService();
