import httpToDashboardApi from "../../BaseServiceHttp";

class DowntimeJustificationService {
  getAvailableDowntimeReasons(productionUnitId, downtimeId) {
    let queryParamString = "";
    if (downtimeId && downtimeId.length > 0) {
      queryParamString = "?downtime_id=" + encodeURIComponent(downtimeId);
    }
    return httpToDashboardApi.get(
      `production-unit/${productionUnitId}/available-downtime-reasons-flatten-tree${queryParamString}`,
    );
  }
  setDowntimeJustifications(downtimeId, newJustifications) {
    return httpToDashboardApi.put(`downtimes/${downtimeId}/downtime-justifications`, newJustifications);
  }
}

export default new DowntimeJustificationService();
