import http from "../../BaseServiceHttp";
import Validations from "@/components/validations";
import store from "@/store";

class ProductionUnitService {
  getProductionUnit(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}`).then((response) => {
      if (response.status === 200) {
        store.dispatch("user/updateProductionUnit", response.data);
      }
      return response;
    });
  }
  getProductionUnitMeasurementActivity(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/measurement-activity`);
  }
  getProductionUnits(factoryId) {
    return http.get(`/factories/${factoryId}/production-units`);
  }
  update(updatedProductionUnit) {
    return http.put(`/production-units/${updatedProductionUnit.id}`, updatedProductionUnit);
  }
  getUnitName(unitId) {
    const found = Validations.getProductionUnitAvailableUnits().filter((unit) => unit.id === unitId);
    if (found && found.length === 1) {
      return found[0].name;
    } else {
      return this.getUnitName(Validations.getDefaultUnit());
    }
  }
  getTimeWithoutMeasureToleranceMillis() {
    return 5 * 60 * 1000; // 5 minutes
  }
  getProductionUnitTags(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/tags`);
  }
  updateProductionUnitTag(productionUnitId, tagId) {
    return http.put(`/production-units/${productionUnitId}/tags/${tagId}`);
  }
  deleteProductionUnitTag(productionUnitId, tagId) {
    return http.delete(`/production-units/${productionUnitId}/tags/${tagId}`);
  }
}

export default new ProductionUnitService();
