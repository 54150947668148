export default {
  name: "graphs",
  namespaced: true,

  state: {
    isMenuOpen: false,
  },

  getters: {
    isMenuOpen(state) {
      return state.isMenuOpen;
    },
  },

  actions: {
    setIsMenuOpen({ commit }, value) {
      commit("setIsMenuOpen", value);
    },
  },

  mutations: {
    setIsMenuOpen(state, value) {
      state.isMenuOpen = value;
    },
  },
};
