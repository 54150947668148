import PackageFeatures from "./PackageFeatures";

class Graphs {
  static oeeTrendGraphName = "oee";
  static quantityGraphName = "quantity";
  static weightGraphName = "weight";
  static volumeGraphName = "volume";
  static lengthGraphName = "length";
  static rejectQuantityGraphName = "reject-quantity";
  static rejectWeightGraphName = "reject-weight";
  static rejectVolumeGraphName = "reject-volume";
  static rejectLengthGraphName = "reject-length";

  oeeTrend = {
    name: Graphs.oeeTrendGraphName,
    requiredFeature: PackageFeatures.oee,
  };
  quantity = {
    name: Graphs.quantityGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  weight = {
    name: Graphs.weightGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  volume = {
    name: Graphs.volumeGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  length = {
    name: Graphs.lengthGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  rejectQuantity = {
    name: Graphs.rejectQuantityGraphName,
    requiredFeature: PackageFeatures.reject,
  };
  rejectWeight = {
    name: Graphs.rejectWeightGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  rejectVolume = {
    name: Graphs.rejectVolumeGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
  rejectLength = {
    name: Graphs.rejectLengthGraphName,
    requiredFeature: PackageFeatures.productQuantity,
  };
}

export default new Graphs();
