const localStorageThemeKey = "dark";

export function isThemeDarkFromLocalStorage() {
  const isLightMode = localStorage.getItem("dark") === "false";
  return !isLightMode;
}

export function setThemeToLocalStorage(isThemeDark) {
  localStorage.setItem(localStorageThemeKey, isThemeDark.toString());
}
