import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    meta: { requiresAuth: false },
    redirect: `/home`,
  },
  {
    path: "/login-callback",
    name: "login-callback",
    component: () => import("../views/LoginCallbackView"),
  },
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/home",
        name: "Home",
        meta: {
          layout: "simple-layout",
          layoutProps: { isLayoutCentered: true },
        },
        component: () => import("../views/HomeView"),
      },
      {
        path: "overview",
        name: "Overview",
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/OverviewView"),
      },
      {
        path: "dashboard/:id",
        name: "Dashboard",
        props: (route) => ({ selectedTab: route.params?.selectedTab }),
        meta: {
          layout: "default-layout",
          layoutProps: { isMainHeightMaximized: true },
          requiresAuth: true,
        },
        component: () => import("../views/DashboardView"),
      },
      {
        path: "production-data-export",
        name: "ProductionDataExport",
        meta: {
          layout: "default-layout",
          requiresAuth: true,
        },
        component: () => import("../views/ProductionDataExportView.vue"),
      },
      {
        path: "production-units",
        name: "ProductionUnits",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/ProductionUnitsView"),
      },
      {
        path: "production-units/:id/measurement-activity",
        name: "ProductionUnitMeasurementActivity",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ProductionUnitMeasurementActivityView.vue"),
      },
      {
        path: "production-units/:id/name/:name",
        name: "ProductionUnitEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ProductionUnitView"),
      },
      {
        path: "products",
        name: "Products",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/ProductsView"),
      },
      {
        path: "products/creation",
        name: "ProductCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ProductView"),
      },
      {
        path: "products/:id",
        name: "ProductEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ProductView"),
      },
      {
        path: "downtime-reasons",
        name: "DowntimeReasons",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/DowntimeReasonsView"),
      },
      {
        path: "downtime-reasons/category/creation",
        name: "DowntimeCategoryCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/DowntimeCategoryView"),
      },
      {
        path: "downtime-reasons/reason/creation",
        name: "DowntimeReasonCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/DowntimeReasonView"),
      },
      {
        path: "downtime-reasons/reason/edition",
        name: "DowntimeReasonEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/DowntimeReasonView"),
      },
      {
        path: "downtime-reasons/category/edition",
        name: "DowntimeCategoryEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/DowntimeCategoryView"),
      },
      {
        path: "reject-reasons",
        name: "RejectReasons",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/RejectReasonsView"),
      },
      {
        path: "reject-reasons/category/creation",
        name: "RejectCategoryCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/RejectCategoryView"),
      },
      {
        path: "reject-reasons/reason/creation",
        name: "RejectReasonCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/RejectReasonView"),
      },
      {
        path: "reject-reasons/reason/edition",
        name: "RejectReasonEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/RejectReasonView"),
      },
      {
        path: "reject-reasons/category/edition",
        name: "RejectCategoryEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/RejectCategoryView"),
      },
      {
        path: "users",
        name: "Users",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/UsersView"),
      },
      {
        path: "user/creation",
        name: "UserCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/UserView"),
      },
      {
        path: "user/:id",
        name: "UserEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/UserView"),
      },
      {
        path: "terms-of-service",
        name: "TermsOfService",
        meta: { requiresAuth: true },
        component: () => import("../views/TermsOfServiceView"),
      },
      {
        path: "shift-schedules",
        name: "ShiftSchedules",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/ShiftSchedulesView"),
      },
      {
        path: "shift/creation",
        name: "ShiftCreation",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ShiftView"),
      },
      {
        path: "shift/:id",
        name: "ShiftEdition",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/ShiftView"),
      },
      {
        path: "product-sampling/:id/:samplingType",
        name: "ProductSampling",
        meta: {
          requiresAuth: true,
          hideHeaderAsideNavigation: true,
        },
        component: () => import("../views/ProductSamplingView"),
      },
      {
        path: "devices",
        name: "Devices",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: false,
        },
        component: () => import("../views/DevicesView"),
      },
      {
        path: "devices/:id",
        name: "DeviceStatus",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/DeviceView"),
      },
      {
        path: "alerts",
        name: "Alerts",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/AlertsView.vue"),
      },
      {
        path: "alerts/:id",
        name: "Alert",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/AlertView.vue"),
      },
      {
        path: "alert/creation",
        name: "CreateAlert",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          disableFactorySelection: true,
        },
        component: () => import("../views/AlertView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

if (process.env.VUE_APP_STYLE_GUIDE_VISIBLE === "true") {
  router.addRoute({
    path: "/styleguide",
    name: "Styleguide",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/StyleguideView"),
  });
}

router.addRoute({
  path: "/*",
  name: "PageNotFound",
  meta: {
    layout: "simple-layout",
    layoutProps: { isLayoutCentered: true },
  },
  component: () => import("../views/PageNotFoundView.vue"),
});
