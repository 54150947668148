import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import OverviewService from "@/components/overview/OverviewService";
import DemoService from "@/components/DemoService";
import TileService from "@/components/dashboard/tiles/TileService";
import { toProductionUnitOverview } from "@/store/modules/OverviewHelper";
import * as OverviewHelper from "@/store/modules/OverviewHelper";
import { SHIFT, PRODUCTION_RUN } from "@/store/TimeUtils";
import { sortCoverageByStartDateDesc } from "@/components/SortUtils";
import { DateTime } from "luxon";
import { toCompletionPercent, toTimeToCompletion } from "@/components/ProductionRunCompletionService";
import * as TimeUtils from "@/store/TimeUtils";

// noinspection DuplicatedCode
export default {
  namespaced: true,

  state: {
    activeKpi: "oee", //default active kpi or selected preference
    activeKpiConfig: {
      product_unit: null,
      scope: null,
      time_unit: null,
    },

    isFullScreen: false,

    productionUnitsStateAndKpis: [],
    productionUnitsSpeed: [],
    activeProductionUnitIdForDetails: null,

    activeProductionUnitsCoverage: [],
    activeProductionUnitsState: [],

    productionUnitDetails: {
      kpis: [],
      downtimes: {
        downtimesToJustify: 0,
        unplannedDowntimes: [],
        plannedDowntimes: [],
      },
      timeDistribution: {
        rawChartData: [],
      },
    },

    // is[placeholder]Loading controls whether a call is currently ongoing. This is so that if for whatever reason
    // a call hangs for a while, we don't start another one before it finishes.

    // is[placeholder]Loaded controls whether there is data to display. View will look at this to either show
    // a skeleton loader or the data. Should only be false on the initial page load and remain true afterwards
    // to avoid flashing cards.
    isOverviewLoading: false,
    isOverviewLoaded: false,

    // This is required in order to be able to calculate time to completion and competion %
    // in the overview for any time frame selected for all PUs.
    productionUnitsByProductionRunMetrics: [],
    productionCompletionByPu: {}
  },

  getters: {
    activeKpi(state) {
      return state.activeKpi;
    },
    activeKpiConfig(state) {
      return state.activeKpiConfig;
    },
    productionUnitsStateAndKpis(state) {
      return state.productionUnitsStateAndKpis;
    },
    productionUnitsSpeed(state) {
      return state.productionUnitsSpeed;
    },
    productionUnitDowntimes(state) {
      return state.productionUnitDetails.downtimes;
    },
    productionUnitTimeDistribution(state) {
      return state.productionUnitDetails.timeDistribution;
    },
    activeProductionUnitIdForDetails(state) {
      return state.activeProductionUnitIdForDetails;
    },
    isOverviewLoaded(state) {
      return state.isOverviewLoaded;
    },
    isOverviewLoading(state) {
      return state.isOverviewLoading;
    },
    activeProductionUnit(state, getters, rootState, rootGetters) {
      const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
      return productionUnits.find((item) => item.id === state.activeProductionUnitIdForDetails);
    },
    isFullScreen(state) {
      return state.isFullScreen;
    },
    activeProductionUnitsState: (state) => {
      return state.activeProductionUnitsState;
    },
    hasAnyActiveTag: (state) => {
      return state.activeProductionUnitsState.some((pu) => pu.tags && pu.tags.length > 0);
    },
    activeProductionUnitsCoverage: (state) => {
      return state.activeProductionUnitsCoverage;
    },
    productionUnitsByProductionRunMetrics: (state) => {
      return state.productionUnitsByProductionRunMetrics;
    },
    productionCompletionByPu: (state) => {
      return state.productionCompletionByPu;
    },
  },

  actions: {
    setActiveKpi({ commit }, { activeKpi, config }) {
      commit("setActiveKpi", activeKpi);

      // If option is not defined default to null
      commit("setActiveKpiConfig", config);
    },

    fetchOverview({ dispatch, state, rootGetters, commit }) {
      let isUserLoggedIn = rootGetters["user/isLoggedIn"];
      let hasOverviewAccess = rootGetters["user/hasOverviewAccess"];
      let activeFactory = rootGetters["navigation/activeFactory"];
      let activeFactoryId = activeFactory.id;
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      dispatch("navigation/setOverviewShiftStarEndTimes", null, { root: true });
      commit("setLoader", ["isOverviewLoading", true]);
      
      let productionUnits = activeFactory?.productionUnits;
      if (!productionUnits) {
        commit("setLoader", ["isOverviewLoading", false]);
        commit("setLoader", ["isOverviewLoaded", true]);
        return;
      }

      if (isUserLoggedIn && activeFactory && hasOverviewAccess) {
        return dispatch("fetchProductionUnitsCoverage", { activeFactoryId, productionUnits })
          .then(() => {
            dispatch("fetchProductionUnitsState", { activeFactoryId, productionUnits })
              .then(() => {
                const productionUnitStates = state.activeProductionUnitsState;
                const productionUnitIds = productionUnits?.map((pu) => pu.id);
                dispatch("fetchProductionUnitsKPIs", {
                  productionUnitIds,
                  activeFactoryId,
                  productionUnitStates,
                  productionUnits,
                })
                  .then(() => {
                    commit("setLoader", ["isOverviewLoading", false]);
                    if (activeFactoryId === rootGetters["navigation/activeFactory"].id) {
                      // only set loaded to true if the data that was loaded is for the correct factory
                      // if not, don't set the loader in case the correct call has already set it
                      commit("setLoader", ["isOverviewLoaded", true]);
                    }
                  })
                  .catch(() => {
                    commit("setLoader", ["isOverviewLoading", false]);
                    commit("setLoader", ["isOverviewLoaded", true]);
                  });
              })
              .catch(() => {
                commit("setLoader", ["isOverviewLoading", false]);
                commit("setLoader", ["isOverviewLoaded", true]);
              });
          })
          .catch(() => {
            commit("setLoader", ["isOverviewLoading", false]);
            commit("setLoader", ["isOverviewLoaded", true]);
          });
      }
    },

    fetchOverviewCompletionKpis({ state, rootGetters, dispatch, commit }) {
      if (!state.activeProductionUnitsCoverage) return;
      let activeFactory = rootGetters["navigation/activeFactory"];
      let productionUnitIds = activeFactory.productionUnits.map((pu) => pu.id);
      dispatch("fetchProductionUnitsProductionRunMetrics", { productionUnitIds }).then(() => {
        const completions = productionUnitIds.map((puId) => {
          let productionRunCoverageForPu =  state.activeProductionUnitsCoverage.find((pu) => pu.production_unit_id === puId)?.production_runs_coverage;
          let currentProductionRunForPu = productionRunCoverageForPu?.[productionRunCoverageForPu.length - 1];

          if (!currentProductionRunForPu) {
            return {
              puId: puId,
              timeToCompletionValue: null,
              completionPercentageValue: null,
              completionNetQuantityValue: null,
              completionPlannedQuantityValue: null
            };
          }

          const productionRunMetricsForPu = state.productionUnitsByProductionRunMetrics.find((metric) => 
            metric.key.production_unit_id === puId 
            && metric.key.sku === currentProductionRunForPu.sku 
            && metric.key.work_order_id === currentProductionRunForPu.work_order_id 
            && metric.key.lot_id === currentProductionRunForPu.lot_id
            && metric.key.start_time === TimeUtils.toEpochMillisUTC(currentProductionRunForPu.start_date)
          );
          
          const timeToCompletionValue = toTimeToCompletion(
            currentProductionRunForPu.planned_quantity, 
            productionRunMetricsForPu?.metrics?.produced_quantity?.total_count, 
            productionRunMetricsForPu?.metrics?.reject_quantity?.total_count, 
            productionRunMetricsForPu?.metrics?.time_distribution
          );

          const completionPercentageValue = toCompletionPercent(
            currentProductionRunForPu.planned_quantity, 
            productionRunMetricsForPu?.metrics?.produced_quantity?.total_count, 
            productionRunMetricsForPu?.metrics?.reject_quantity?.total_count
          );
  
  
          return {
            puId: puId,
            timeToCompletionValue: timeToCompletionValue?.completionTime,
            completionPercentageValue: completionPercentageValue?.completionPercent,
            completionNetQuantityValue: completionPercentageValue?.netQuantity,
            completionPlannedQuantityValue: completionPercentageValue?.plannedQuantity
          };
        });

        // Group results by pu ID
        const completionByPu = completions.reduce((acc, current) => {
            acc[current.puId] = current;
            return acc;
        }, {});
        commit("setProductionCompletionByPu", completionByPu);
      });
    },

    fetchProductionUnitsCoverage({ commit, rootGetters }, { activeFactoryId, productionUnits }) {
      const startTime = rootGetters["navigation/startISO"];
      const endTime = rootGetters["navigation/endISO"];
      if (!startTime && !endTime) return;
      const productionUnitIds = productionUnits.map((pu) => pu.id);
      return OverviewService.getProductionUnitsCoverage(activeFactoryId, productionUnitIds, startTime, endTime)
        .then((httpResponse) => {
          // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
          if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
          if (httpResponse.status === 200) {
            commit("setActiveProductionUnitsCoverage", httpResponse.data.production_units_coverage);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) => {
              switch (code) {
                case "DSH_COV_GET_10007":
                case "DSH_COV_GET_10008":
                  return null; // No error messages for this backend error code. It's a race condition.
                default:
                  return i18n.t("common.errors.default", { code: error });
              }
            }),
            { root: true },
          );
        });
    },

    async fetchProductionUnitsState({ commit, state, rootGetters }, { activeFactoryId, productionUnits }) {
      const email = rootGetters["user/email"];
      const isLiveData = rootGetters["navigation/isLiveData"];
      const startTime = rootGetters["navigation/startISO"];
      const endTime = rootGetters["navigation/endISO"];

      if (isLiveData) {
        return OverviewService.getProductionUnitsCurrentState(activeFactoryId, startTime, endTime)
          .then((httpResponse) => {
            // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
            if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
            if (httpResponse.status === 200) {
              let productionUnitStates = httpResponse.data.map((o, index) => {
                const productionUnit = productionUnits.find((pu) => pu.id === o.production_unit_id);
                let puStatus = o.production_unit_state.toLowerCase();
                if (!isLiveData) {
                  puStatus = "carbon-frozen"; // carbon frozen ? Yes, like Han Solo in `The Empire Strikes Back` :D
                }
                return {
                  kpiSelected: state.activeKpi,
                  puId: o.production_unit_id,
                  puName: DemoService.maskProductionUnitNameIfNecessary(email, productionUnit.name, index + 1),
                  puStatus: puStatus,
                  productSku: o.current_product_sku,
                  productName: DemoService.maskProductNameIfNecessary(email, o.current_product_name),
                  tags: mapTags(o.tags),
                };
              });

              let index = productionUnitStates.length;
              productionUnits.forEach((pu) => {
                const puState = productionUnitStates.find((puState) => puState.puId === pu.id);
                if (puState === null || puState === undefined) {
                  productionUnitStates.push({
                    kpiSelected: state.activeKpi,
                    puId: pu.id,
                    puName: DemoService.maskProductionUnitNameIfNecessary(email, pu.name, index + 1),
                    puStatus: "carbon-frozen",
                    productSku: null,
                    productName: DemoService.maskProductNameIfNecessary(email, null),
                    tags: [],
                  });
                }
              });

              commit("setActiveProductionUnitsState", productionUnitStates);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, (code) =>
                i18n.t("common.errors.default", { code: code }),
              ),
              { root: true },
            );
          });
      } else {
        // Past data
        const productionUnits = rootGetters["navigation/activeFactoryProductionUnits"];
        const productionUnitStates = state.activeProductionUnitsCoverage.map((puCoverage, index) => {
          const productionUnit = productionUnits.find((pu) => pu.id === puCoverage.production_unit_id);

          let lastProductionRun = {
            sku: null,
            product_name: null,
          };
          if (puCoverage.production_runs_coverage && puCoverage.production_runs_coverage.length > 0) {
            lastProductionRun = puCoverage.production_runs_coverage[puCoverage.production_runs_coverage.length - 1];
          }
          return {
            kpiSelected: state.activeKpi,
            puId: productionUnit.id,
            puName: DemoService.maskProductionUnitNameIfNecessary(email, productionUnit.name, index + 1),
            puStatus: "carbon-frozen", // carbon frozen ? Yes, like Han Solo in `The Empire Strikes Back` :D
            productSku: lastProductionRun.sku,
            productName: DemoService.maskProductNameIfNecessary(email, lastProductionRun.product_name),
          };
        });
        commit("setActiveProductionUnitsState", productionUnitStates);
        return new Promise((resolve) => resolve("done"));
      }
    },

    async fetchProductionUnitsProductionRunMetrics(
      { commit, state, rootGetters },
      { productionUnitIds, startDate, endDate }
    ) {

      let earliestStartDate = null;
      let latestEndDate = null;

      if (!startDate && !endDate) {
        if (!state.activeProductionUnitsCoverage || state.activeProductionUnitsCoverage.length === 0) return;
        state.activeProductionUnitsCoverage.forEach(unit => {
            unit.production_runs_coverage.forEach(run => {
                if (run.start_date) {
                    if (!earliestStartDate || new Date(run.start_date) < new Date(earliestStartDate)) {
                        earliestStartDate = run.start_date;
                    }
                }

                if (run.end_date) {
                    if (!latestEndDate || new Date(run.end_date) > new Date(latestEndDate)) {
                        latestEndDate = run.end_date;
                    }
                }
                else
                  latestEndDate = null;
            });
        });
      } else {
        earliestStartDate = startDate;
        latestEndDate = endDate;
      }

      const timezone = rootGetters["navigation/activeFactory"].timezone;
      latestEndDate = latestEndDate ? latestEndDate : DateTime.now().setZone(timezone).toISO();
      
      return TileService.getProductionUnitMetrics(
        productionUnitIds,
        PRODUCTION_RUN,
        earliestStartDate,
        latestEndDate,
        null,
        null,
      ).then((httpResponse) => {
        if (httpResponse.status === 200) {
          let metricsByPU = httpResponse.data;
          commit("setProductionUnitsByProductionRunMetrics", metricsByPU);
        }
      }).catch((error) => {
        commit(
          "operation/showOperationError",
          ErrorHandling.buildErrorsMessages(error.response, (code) =>
            i18n.t("common.errors.default", { code: code }),
          ),
          { root: true },
        );
      });
    },

    async fetchProductionUnitsKPIs(
      { commit, dispatch, state, rootGetters },
      { productionUnitIds, activeFactoryId, productionUnitStates, productionUnits },
    ) {
      if (productionUnitStates === null || productionUnitStates === undefined || productionUnitStates.length === 0) {
        return;
      }
      return TileService.getProductionUnitMetrics(
        productionUnitIds,
        rootGetters["navigation/timeFrame"],
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
        rootGetters["navigation/date"], // Business Day as selected by the user
        null,
      )
        .then((httpResponse) => {
          // if IDs don't match, factory was changed while overview was fetching. Don't set this data as it's not good anymore
          if (activeFactoryId !== rootGetters["navigation/activeFactory"].id) return;
          const email = rootGetters["user/email"];

          let productionUnitOverviews = [];
          if (httpResponse.status === 200) {
            let metricsByPU = httpResponse.data;
            if (rootGetters["navigation/timeFrame"] === SHIFT) {
              metricsByPU = OverviewHelper.extractLatestMetricsByShift(httpResponse.data, productionUnits);
            }
            productionUnitOverviews = metricsByPU.map((puMetrics, index) => {
              let puOverview = toProductionUnitOverview(puMetrics, productionUnitStates, productionUnits);
              // Add property `kpiSelected`
              puOverview["kpiSelected"] = state.activeKpi;
              // Overwrite the `puName` for demo purposes?
              puOverview["puName"] = DemoService.maskProductionUnitNameIfNecessary(email, puOverview.puName, index + 1);
              return puOverview;
            });
            const sortedList = productionUnitOverviews.sort((o1, o2) => {
              if (o1.puName < o2.puName) return -1;
              if (o1.puName > o2.puName) return 1;
              return 0;
            });
            const forceSpeedRetrieval = false;
            dispatch("fetchProductionUnitsSpeed5Minutes", { activeFactoryId, forceSpeedRetrieval });
            commit("setProductionUnitsStateAndKpis", sortedList);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchProductionUnitsSpeed5Minutes({ commit, state, rootGetters }, { activeFactoryId, forceSpeedRetrieval }) {
      const isLiveData = rootGetters["navigation/isLiveData"];
      if (!isLiveData) {
        commit("setProductionUnitsSpeed", []);
        return;
      }
      if (!forceSpeedRetrieval) {
        // We will fetch the Speed only if the KPI is selected
        if (state.activeKpi !== "product-speed-5m" && state.activeKpi !== "product-speed-5m-per-minute") {
          // no need to query the Speed, it is not selected
          commit("setProductionUnitsSpeed", []);
          return;
        }
      }
      if (!activeFactoryId) return;
      OverviewService.getProductionUnitsSpeed(
        activeFactoryId,
        rootGetters["navigation/startISO"],
        rootGetters["navigation/endISO"],
      )
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            commit("setProductionUnitsSpeed", httpResponse.data);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },

    fetchProductionUnitOverview({ commit, getters, rootGetters, dispatch, state }, productionUnitId) {
      dispatch("navigation/setCurrentDateTime", null, { root: true });
      commit("setActiveProductionUnitIdForDetails", productionUnitId);
      const forceSpeedRetrieval = true;
      let activeFactory = rootGetters["navigation/activeFactory"];
      let activeFactoryId = activeFactory.id;
      dispatch("fetchProductionUnitsSpeed5Minutes", { activeFactoryId, forceSpeedRetrieval });

      let startDateTime = rootGetters["navigation/startDateTime"];
      let endDateTime = rootGetters["navigation/endDateTime"];

      if (rootGetters["navigation/timeFrame"] === SHIFT) {
        const productionUnitCoverage = state.activeProductionUnitsCoverage?.find((cov) => cov.production_unit_id === productionUnitId);
        if (!productionUnitCoverage) return;
        const sortedWorkShiftCoverage = productionUnitCoverage.work_shifts_coverage.sort(sortCoverageByStartDateDesc);
        const currentWorkShift = sortedWorkShiftCoverage[0];
        startDateTime = DateTime.fromISO(currentWorkShift.start_date).setZone(activeFactory.timezone);
        endDateTime = DateTime.now().setZone(activeFactory.timezone).set({ millisecond: 0, second: 0 });
      }

      if (
        startDateTime.second > 0 ||
        startDateTime.millisecond > 0 ||
        endDateTime.second > 0 ||
        endDateTime.millisecond > 0
      ) {
        return;
      }

      OverviewService.getProductionUnitOverviewDetails(
        productionUnitId,
        startDateTime,
        endDateTime,
      )
        .then((puOverviewDetailsHttpResponse) => {
          if (puOverviewDetailsHttpResponse.status === 200) {
            const puOverviewDetails = puOverviewDetailsHttpResponse.data;

            const topPlannedDowntimeReasons = puOverviewDetails.top_planned_downtime_reasons.map((r) => ({
              durationSec: r.total_downtime_duration_seconds,
              reason: "[" + r.reason_code + "] " + r.reason_name,
            }));
            const topUnplannedDowntimeReasons = puOverviewDetails.top_unplanned_downtime_reasons.map((r) => ({
              durationSec: r.total_downtime_duration_seconds,
              reason: "[" + r.reason_code + "] " + r.reason_name,
            }));

            let delayInSec = getters.activeProductionUnit.downtimeJustificationDelay;
            const delayInMillis = delayInSec && delayInSec > 0 ? delayInSec * 1000 : 0;
            const downtimesToJustify = puOverviewDetails.unjustified_downtimes.filter(
              (d) => d.end_time - d.start_time >= delayInMillis,
            ).length;
            const downtimes = {
              downtimesToJustify: downtimesToJustify,
              unplannedDowntimes: topUnplannedDowntimeReasons,
              plannedDowntimes: topPlannedDowntimeReasons,
            };

            let distributions = [];
            if (puOverviewDetails.time_distribution.uptime > 0) {
              distributions.push({
                type: "active",
                seconds: puOverviewDetails.time_distribution.uptime / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_planned > 0) {
              distributions.push({
                type: "planned-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_planned / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_unplanned > 0) {
              distributions.push({
                type: "justified-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_unplanned / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.downtime_unjustified > 0) {
              distributions.push({
                type: "unjustified-downtime",
                seconds: puOverviewDetails.time_distribution.downtime_unjustified / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.out_of_production_time > 0) {
              distributions.push({
                type: "out-of-production",
                seconds: puOverviewDetails.time_distribution.out_of_production_time / 1000,
              });
            }
            if (puOverviewDetails.time_distribution.unknown_time > 0) {
              distributions.push({
                type: "unknown",
                seconds: puOverviewDetails.time_distribution.unknown_time / 1000,
              });
            }
            const timeDistribution = {
              rawChartData: distributions,
              rawTimings: puOverviewDetails.time_distribution,
            };
            const puDetails = {
              downtimes: downtimes,
              timeDistribution: timeDistribution,
            };
            commit("setProductionUnitDetails", puDetails);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },

    setActiveProductionUnitIdForDetails({ commit }, puId) {
      commit("setActiveProductionUnitIdForDetails", puId);
    },

    clearProductionUnitIdForDetails({ commit }) {
      commit("setActiveProductionUnitIdForDetails", null);
    },

    setIsFullScreen({ commit }, value) {
      commit("setIsFullScreen", value);
    },

    resetOverviewLoaders({ commit }) {
      commit("setLoader", ["isOverviewLoaded", false]);
    },
  },

  mutations: {
    setLoader(state, [loader, value]) {
      state[loader] = value;
    },
    setActiveKpi(state, activeKpi) {
      state.activeKpi = activeKpi;
    },
    setActiveKpiConfig(state, config) {
      state.activeKpiConfig.product_unit = config?.product_unit ?? null;
      state.activeKpiConfig.scope = config?.scope ?? null;
      state.activeKpiConfig.time_unit = config?.time_unit ?? null;
    },
    setProductionUnitsStateAndKpis(state, pu) {
      state.productionUnitsStateAndKpis = pu;
    },
    setProductionUnitsSpeed(state, puSpeed) {
      state.productionUnitsSpeed = puSpeed;
    },
    setProductionUnitDetails(state, puDetails) {
      state.productionUnitDetails = puDetails;
    },
    setActiveProductionUnitIdForDetails(state, puId) {
      state.activeProductionUnitIdForDetails = puId;
    },
    setActiveProductionUnitsCoverage(state, value) {
      state.activeProductionUnitsCoverage = value;
    },
    setActiveProductionUnitsState(state, value) {
      state.activeProductionUnitsState = value;
    },
    setIsFullScreen(state, value) {
      state.isFullScreen = value;
    },
    setProductionUnitsByProductionRunMetrics(state, value) {
      state.productionUnitsByProductionRunMetrics = value;
    },
    setProductionCompletionByPu(state, value) {
      state.productionCompletionByPu = value;
    }
  },
};

function mapTags(tags) {
  return tags
    ? tags.map((tag) => ({
        id: tag.tag_id,
        since: tag.since,
      }))
    : [];
}
