import { LogLevel } from "@azure/msal-browser";
import { b2cPolicies } from "./policies";
import { apiConfig } from "./dashboardApiConfig";
import i18n from "../i18n";
import vuetify from "../plugins/vuetify";

//  Configuration object to be passed to MSAL instance on creation.
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AUTHENTICATION_MSAL_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority, // user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.VUE_APP_AUTHENTICATION_DEFAULT_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: process.env.VUE_APP_URL_TO_TILEPLUS,
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (level === LogLevel.Error) console.error(message);
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false, // If true, personally identifiable information (PII) is included in logs.
  },
};

//Scopes: By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
export function loginRequest() {
  return {
    scopes: ["openid", "offline_access", "profile", ...apiConfig.b2cScopes],
    extraQueryParameters: {
      light_theme: !vuetify.framework.theme.dark,
      ui_locales: getB2CLocale(),
      theme: vuetify.framework.theme.dark ? "dark" : "light",
    },
  };
}

export function getB2CLocale() {
  return i18n.locale === "zh" ? "zh-hans" : i18n.locale;
}

// Scopes: scopes used to request a token from AD B2C for accessing a protected resource.
export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false, // true: to skip a cached token and go to the server to get a new token
};
