<template>
  <inline-svg :src="require('@/assets/custom-icons/' + svgFileName)" />
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
  name: "WxCustomIcon",
  components: { InlineSvg },
  props: {
    svgFileName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep line,
::v-deep polyline,
::v-deep rect {
  stroke: currentColor;
  fill: currentColor;
}

::v-deep polygon,
::v-deep path {
  fill: currentColor;
}

::v-deep g {
  fill: none;
}
</style>
