<script>
import { VBtn } from "vuetify/lib";

export default {
  name: "WxBtnIcon",
  extends: VBtn,
  props: {
    depressed: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  &.neutral {
    background: none !important;

    &.v-btn--disabled {
      background-color: transparent !important;
    }

    &::before {
      background-color: var(--color-contrast-theme);
      opacity: var(--opacity-before-effect-low);
    }

    &:hover,
    &:focus {
      &.neutral::before {
        opacity: var(--opacity-before-effect-high);
      }
    }
  }
  &.neutral--text,
  &.secondary--text {
    color: var(--color-text-theme) !important;
    caret-color: var(--color-text-theme) !important;
  }

  &.v-size--default {
    min-width: auto;
    width: var(--btn-height-default);
    padding: 0;
  }
  @at-root .v-btn-toggle:not(.v-btn-toggle--dense) #{&}.v-size--default {
    min-width: auto;
    width: var(--btn-height-default);
    min-height: auto;
    height: var(--btn-height-default);
  }

  &.v-size--small {
    min-width: auto;
    width: var(--btn-height-sm);
    padding: 0;
  }
  @at-root .v-btn-toggle:not(.v-btn-toggle--dense) #{&}.v-size--small {
    min-width: auto;
    width: var(--btn-height-sm);
    min-height: auto;
    height: var(--btn-height-sm);
  }

  /** --------------
   * Grouped buttons
   */
  @at-root .v-btn-toggle #{&}:not(:first-child) {
    margin-left: 0;
  }
  @at-root .v-btn-toggle #{&}:not(:last-child) {
    margin-right: 0;
  }
}
</style>
