class ErrorHandling {
  defaultBackendServiceErrorCode = 500;

  buildErrorsMessages(httpResponse, getErrorMessageCallback, joinErrors) {
    let doJoinErrors = joinErrors !== false;
    // Temporary patch to hide 500 errors from clients until we fix current problem
    if (httpResponse && httpResponse.status === 500) return null;
    if (httpResponse && httpResponse.data && httpResponse.data.errors) {
      let serviceErrors = httpResponse.data.errors;
      if (serviceErrors) {
        let errors = serviceErrors
          .map((e) => getErrorMessageCallback(e.code, e.arguments, e.message))
          .filter((e) => e !== null);
        if (errors && errors.length > 0) {
          if (doJoinErrors) {
            return [errors.join("\n")];
          } else {
            return errors;
          }
        } else {
          return null;
        }
      } else {
        return this.buildUnexpectedErrorMessages(getErrorMessageCallback);
      }
    }
    return null;
  }

  buildUnexpectedErrorMessages(getErrorMessageCallback) {
    let operationStatusMessage = getErrorMessageCallback(this.defaultBackendServiceErrorCode);
    return [operationStatusMessage];
  }
}

export default new ErrorHandling();
