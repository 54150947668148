import i18n from "@/i18n";
import ErrorHandling from "@/components/ErrorHandling";

export default {
  namespaced: true,

  state: {
    snackBar: {
      status: "",
      title: "",
      messages: [],
      show: false,
      timeout: 5000,
    },
  },

  getters: {
    snackBar(state) {
      return state.snackBar;
    },
  },

  actions: {
    showOperationSuccess({ commit }, message) {
      commit("showOperationSuccess", message);
    },
    showOperationWarning({ commit }, { title, message, timeout }) {
      commit("showOperationWarning", { title, message, timeout });
    },
    showOperationError({ commit }, message) {
      if (message && message.length > 0) {
        commit("showOperationError", message);
      }
    },
    setDisplayTimeout({ commit }, timeout) {
      commit("setDisplayTimeout", timeout);
    },
    hideOperation({ commit }) {
      commit("hideOperation");
    },
    handleHttpError({ commit }, httpError) {
      commit("showOperationError", ErrorHandling.buildErrorsMessages(httpError.response, (code) => {
        return i18n.t("common.errors.default", { code: code });
      }))
    }
  },

  mutations: {
    showOperationSuccess(state, message) {
      Object.assign(state.snackBar, {
        status: "success",
        title: i18n.t("common.operation.success"),
        messages: [message],
        show: true,
        timeout: state.snackBar.timeout,
      });
    },
    showOperationWarning(state, { title, message, timeout }) {
      Object.assign(state.snackBar, {
        status: "warning",
        title: title,
        messages: [message],
        show: true,
        timeout: timeout ? timeout : 5000,
      });
    },
    showOperationError(state, messages) {
      if (messages && messages.length > 0) {
        const noneEmptyMessages = messages.filter((m) => m.length > 0);
        if (noneEmptyMessages.length > 0) {
          Object.assign(state.snackBar, {
            status: "error",
            title: i18n.t("common.operation.error"),
            messages: messages,
            show: true,
            timeout: state.snackBar.timeout,
          });
        }
      }
    },
    hideOperation(state) {
      Object.assign(state.snackBar, {
        status: "",
        title: null,
        messages: [],
        show: false,
        timeout: 5000,
      });
    },
  },
  setDisplayTimeout(state, timeout) {
    state.snackBar.timeout = timeout;
  },
};
