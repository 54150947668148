/* eslint-disable no-unused-vars */
import http from "../../BaseServiceHttp";
class GraphService {
  getGraphElements(productionUnitId, startTime, endTime, timeAggregation, optionalProductionRunOptions) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    const skuParam = optionalProductionRunOptions?.sku ? `&sku=${encodeURIComponent(optionalProductionRunOptions.sku)}` : "";
    const workOrderIdParam = optionalProductionRunOptions?.workOrderId ? `&work_order_id=${encodeURIComponent(optionalProductionRunOptions.workOrderId)}` : "";
    const lotId = optionalProductionRunOptions?.lotId ? `&lot_id=${encodeURIComponent(optionalProductionRunOptions.lotId)}` : "";
    let url = `/production-units/${productionUnitId}/graph-elements?start_time=${startParam}&end_time=${endParam}&interval_duration=${timeAggregation}${skuParam}${workOrderIdParam}${lotId}`;
    if (optionalProductionRunOptions) {
      // Fetch metrics for a Production Run
      url = url + "&is_production_run=true";
    }
    return http.get(url);
  }

  getGiveawayElements(productionUnitId, startTime, endTime, chartType) {
    const startParam = encodeURIComponent(startTime);
    const endParam = encodeURIComponent(endTime);
    return http.get(`/production-units/${productionUnitId}/giveaway-spc?chart_type=${chartType}&start_time=${startParam}&end_time=${endParam}`);
  }
}

export default new GraphService();
