export default {
  namespaced: true,

  state: {
    searchKeyword: "",
    selectedProductionUnitId: null,
  },

  getters: {
    searchKeyword(state) {
      return state.searchKeyword;
    },
    selectedProductionUnitId(state) {
      return state.selectedProductionUnitId;
    },
  },

  actions: {
    setSearchKeyword({ commit }, keyword) {
      commit("setSearchKeyword", keyword);
    },
    setSelectedProductionUnitId({ commit }, productionUnitId) {
      commit("setSelectedProductionUnitId", productionUnitId);
    },
  },

  mutations: {
    setSearchKeyword(state, keyword) {
      state.searchKeyword = keyword;
    },
    setSelectedProductionUnitId(state, productionUnitId) {
      state.selectedProductionUnitId = productionUnitId;
    },
  },
};
