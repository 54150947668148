import * as msal from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import store from "../store";
import router from "../router";
import UserService from "@/components/user/UserService";
import i18n, { setLanguageToLocalStorage } from "@/i18n";
import RouteService from "@/router/RouteService";
import ErrorHandling from "@/components/ErrorHandling";
import { setThemeToLocalStorage } from "@/components/Theme";
import { getTileName } from "@/components/Tiles";

export const msalClient = new msal.PublicClientApplication(msalConfig);

msalClient
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.log(error);
    // Cancels forgot password
    if (error.errorMessage.indexOf("AADB2C90091") > -1) {
      signOut();
    }
    if (error.errorMessage.indexOf("AADB2C90027") > -1) {
      store.commit("operation/showOperationError", [i18n.t("homepage.unknownUser")]);
      store.commit("user/clearUser");
      localStorage.clear();
      sessionStorage.clear();
      window.setTimeout(() => {
        msalClient.loginRedirect(loginRequest()).catch((err) => {
          console.log(err);
          store.commit("user/clearUser");
        });
      }, 5000);
    }
  });

msalClient.addEventCallback((message) => {
  if (message.eventType === msal.EventType.LOGOUT_SUCCESS) {
    sessionStorage.clear();
    store.commit("user/clearUser");
  }
});

function handleResponse(authenticationResult) {
  if (authenticationResult) {
    processResponse(authenticationResult);
    router.push(RouteService.toUserHome()).catch(() => {});
  }
}

async function processResponse(authenticationResult) {
  setUser(authenticationResult);
  await loadPackageDefinitions();
  await setUserProfile(authenticationResult);
}

export function setUser(user) {
  const userId = user.idTokenClaims.user_id;
  const userRole = user.idTokenClaims.role;
  store.commit("user/setUser", {
    userId: userId,
    email: user.idTokenClaims.email,
    username: user.idTokenClaims.display_name,
    role: userRole,
    isAdmin: user.idTokenClaims.is_admin,
    isFinancialAdmin: user.idTokenClaims.is_financial_admin,
    accountId: user.idTokenClaims.account_id,
    presenterKey: user.idTokenClaims.presenter_key,
    authorizedPuIds: user.idTokenClaims.production_units,
  });
  localStorage.setItem("currentUserId", userId);
}

export async function setUserProfile(user) {
  const userId = user.idTokenClaims.user_id;
  const userRole = user.idTokenClaims.role;
  const accountId = user.idTokenClaims.account_id;
  const isUserTilelyticsEnabled = user.idTokenClaims.is_tilelytics;
  const authorizedPuIds = user.idTokenClaims.production_units;
  store.commit("user/setTilelyticsEnabled", isUserTilelyticsEnabled);
  await store.dispatch("user/fetchUserFactories", userId, userRole);
  await store.dispatch("user/fetchUserAccount", accountId);
  await loadUserPreferences(authorizedPuIds);
}

async function loadUserPreferences(authorizedPuIds) {
  let preferences;
  await UserService.getUserPreferences(authorizedPuIds)
    .then((response) => {
      if (response.status === 200) {
        preferences = response.data;
      }
    })
    .catch((error) => {
      store.commit(
        "operation/showOperationError",
        ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
        { root: true },
      );
    });
  let theme = store.getters["user/theme"];
  if (preferences) {
    theme = preferences.theme;
    let selectedProductionUnitId = preferences.dashboards.selected_production_unit_id;
    // Set the factory that belongs to the user's selected PU
    const userProductionUnits = store.getters["user/availableProductionUnits"];
    const selectedProductionUnit = userProductionUnits.find((pu) => pu.id === selectedProductionUnitId);
    const selectedFactoryId = selectedProductionUnit.factoryId;
    const userFactories = store.getters["user/factories"];
    const selectedFactory = userFactories.find((f) => f.id === selectedFactoryId);
    await store.dispatch("navigation/setActiveFactory", {
      activeFactory: selectedFactory,
      optionalTimeFrame: preferences.selected_period,
      isSilent: true,
    });

    // set overview preferences
    const activeKpi = getTileName(preferences.overview.selected_kpi, preferences.overview);
    const config = preferences.overview;
    store.dispatch("overview/setActiveKpi", { activeKpi, config });

    // Set dashboard preferences
    store.dispatch("dashboard/setActiveProductionUnitId", selectedProductionUnit.id);

    const tiles = preferences.dashboards.production_units.find((pu) => pu.id === selectedProductionUnitId).tiles;
    store.commit("dashboard/setCurrentTileSelection", UserService.buildTileSelection(tiles));

    const graphPreference = preferences.dashboards.production_units.find((pu) => pu.id === selectedProductionUnitId).graph;
    const graph = graphPreference ? graphPreference : "quantity";
    store.commit("dashboard/setCurrentGraphSelection", graph);

    const tab = preferences.dashboards?.production_units?.find((pu) => pu.id === selectedProductionUnit.id)?.tab;
    const tabSelection = tab ?? "timeline";
    const isGiveawayTabSelected = selectedProductionUnit.packages.includes("SF4") && tabSelection === "giveaway";
    store.commit("dashboard/setGiveawayViewToggle", isGiveawayTabSelected);

    store.commit("user/setPreferences", preferences);
  }
  i18n.locale = store.getters["user/language"];
  setLanguageToLocalStorage(i18n.locale);
  setThemeToLocalStorage(theme === "dark");
}

async function loadPackageDefinitions() {
  await store.dispatch("packages/fetchPackageDefinitions");
}

export function signIn() {
  try {
    msalClient.loginRedirect(loginRequest());
  } catch (err) {
    console.log(err);
    store.commit("user/clearUser");
  }
}

export function signOut() {
  const logoutRequest = {
    account: getUser(),
  };
  msalClient
    .logoutRedirect(logoutRequest)
    .then(() => store.commit("user/clearUser"))
    .catch((err) => {
      console.log(err);
    });
  localStorage.removeItem("currentUserId");
}

export async function getTokenRedirect(request) {
  request.account = getUser();
  return msalClient
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new msal.InteractionRequiredAuthError();
      }
      return response.accessToken;
    })
    .catch((error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalClient.acquireTokenRedirect(request);
      } else {
        if (error instanceof msal.BrowserAuthError) {
          let errorCode = error.errorCode;
          if (errorCode === "no_account_error" || errorCode === "post_request_failed") {
            // fallback to interaction when silent call fails
            return msalClient.acquireTokenRedirect(request);
          } else {
            // If the error is something like "no_network_connectivity", return null and wait for the next call to `getTokenRedirect`
            return null;
          }
        } else {
          console.log(error);
        }
      }
    });
}

export function getUser() {
  if (!msalClient) {
    return null;
  }
  const currentAccounts = msalClient.getAllAccounts();
  if (currentAccounts.length === 0) {
    // No user signed in
    return null;
  } else {
    if (currentAccounts.length === 1) {
      return addUsername(currentAccounts[0]);
    } else {
      let currentUserId = localStorage.getItem("currentUserId");
      if (!currentUserId) {
        return null;
      }
      let currentAccount = currentAccounts.find((a) => a.idTokenClaims.user_id === currentUserId);
      if (!currentAccount) {
        return null;
      } else {
        return addUsername(currentAccount);
      }
    }
  }
}

function addUsername(account) {
  account.username = account.idTokenClaims.email;
  return account;
}
