import DeviceService from "@/components/devices/DeviceService";
import DataSourceService from "@/components/devices/DataSourceService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";

export default {
  namespaced: true,

  state: {
    devices: [],
    dataSources: [],
  },

  getters: {
    activeFactoryDevices(state) {
      return state.devices;
    },
    activeFactoryDataSources(state) {
      return state.dataSources;
    },
  },

  actions: {
    fetchActiveFactoryDevices({ commit }, activeFactoryId) {
      DeviceService.getDevices(activeFactoryId)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            commit("setDevices", httpResponse.data);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },
    fetchActiveFactoryDataSources({ commit }, activeFactoryId) {
      return DataSourceService.getDataSources(activeFactoryId)
        .then((httpResponse) => {
          if (httpResponse.status === 200) {
            commit("setDataSources", httpResponse.data);
          }
        })
        .catch((error) => {
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          );
        });
    },
  },

  mutations: {
    setDevices(state, devices) {
      state.devices = devices;
    },
    setDataSources(state, dataSources) {
      state.dataSources = dataSources;
    },
  },
};
