<template>
  <v-navigation-drawer
    app
    floating
    :expand-on-hover="!$vuetify.breakpoint.mobile"
    :value="value"
    :mini-variant="!isMobile"
    overlay-color="var(--color-overlayBackground)"
    @input="$emit('input', $event)"
    class="wxapp-main-navigation"
  >
    <v-btn
      v-if="!$vuetify.breakpoint.mobile"
      :to="getDestinationRoute"
      exact
      depressed
      plain
      color="primary"
      class="wxapp-logo--drawer"
      accesskey="t"
      :title="$t('mainNav.logoAltText')"
    />

    <v-list nav dense>
      <!--
        Factory Overview link
        -->
      <v-list-item :to="getOverviewRoute">
        <v-list-item-icon>
          <v-icon>mdi-chart-donut</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("mainNav.overview") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
        Dashboard single link (if there's only one PU)
        -->
      <v-list-item v-if="activeFactoryProductionUnits.length < 2" :to="getDashboardRoute">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("mainNav.dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
        Dashboard accordion sub-links (if there's multiple PUs)
        -->
      <v-list-group v-else no-action eager :class="{ 'custom-group-active': isDashboardUrl }">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("mainNav.dashboard") }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(pu, index) in sortedProductionUnits"
          :key="`navPuItem-${index}`"
          @click="setActivePUId(pu.id)"
          :class="{ 'v-list-item--active': isActiveProductionUnitId(pu.id) }"
        >
          <v-list-item-content>
            <v-list-item-title>{{ pu.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!--
        ProductionData Export link
        -->
      <v-list-item v-if="!isPresenter" :to="getProductionDataExportRoute">
        <v-list-item-icon>
          <v-icon>mdi-cloud-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("mainNav.productionDataExport") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
        Admin sub-links
        -->
      <v-list-group v-if="isAdmin" no-action eager :class="{ 'custom-group-active': isAdminUrl }">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("mainNav.admin") }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item 
          v-for="(item, index) in filteredNavAdminItems" 
          :key="`navAdminItem-${index}`" 
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!--
        Styleguide sub-links (for Dev & Staging, NOT Prod)
        -->
      <v-list-item v-if="isStyleNotesVisible" :to="getStyleguideRoute" accesskey="d">
        <v-list-item-icon>
          <v-icon>mdi-palette</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("mainNav.styleguide") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <section v-if="isStyleNotesVisible" :to="getStyleguideRoute">
      <a
        id="styleguide-breakpoint-label"
        target="confluence"
        href="https://worximity.atlassian.net/l/c/tbbudqAr"
        title="Responsive Breakpoint"
      ></a>
      <!--
        Floating Action Button: Theme toggle switch
        -->
      <v-fab-transition>
        <v-btn
          id="themeToggleFab"
          color="black"
          elevation="10"
          accesskey="c"
          dark
          fab
          x-small
          fixed
          bottom
          left
          :title="this.$t('settings.dark')"
          v-if="!this.$vuetify.theme.dark"
          @click="setTheme('dark')"
        >
          <v-icon color="white">mdi-moon-waning-crescent</v-icon>
        </v-btn>
        <v-btn
          id="themeToggleFab"
          color="white"
          elevation="10"
          accesskey="c"
          light
          fab
          x-small
          fixed
          bottom
          left
          :title="this.$t('settings.light')"
          v-if="this.$vuetify.theme.dark"
          @click="setTheme('light')"
        >
          <v-icon color="black">mdi-white-balance-sunny</v-icon>
        </v-btn>
      </v-fab-transition>
    </section>
  </v-navigation-drawer>
</template>

<script>
import RouteService from "@/router/RouteService";
import { setThemeToLocalStorage } from "@/components/Theme";
import { mapGetters, mapActions } from "vuex";
import { compareName } from "@/components/SortUtils";

export default {
  name: "MainNavigation",
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isPresenter", "preferences"]),
    ...mapGetters("navigation", ["activeFactoryProductionUnits", "activeFactory"]),
    ...mapGetters("dashboard", ["activeProductionUnitId"]),
    getDestinationRoute() {
      return RouteService.toUserHome();
    },
    getDashboardRoute() {
      return RouteService.toDashboard(this.activeProductionUnitId);
    },
    getStyleguideRoute() {
      return RouteService.toStyleGuide();
    },
    getOverviewRoute() {
      return RouteService.toOverview();
    },
    getProductionDataExportRoute() {
      return RouteService.toProductionDataExportRoute();
    },
    isStyleNotesVisible() {
      return process.env.VUE_APP_STYLE_GUIDE_VISIBLE === "true";
    },
    currentUrl() {
      return this.$route.path;
    },
    isDashboardUrl() {
      return this.currentUrl.includes("dashboard");
    },
    isAdminUrl() {
      return this.$route.meta.requiresAdmin === true;
    },
    navAdminItems() {
      return [
        {
          title: this.$t("mainNav.users"),
          to: RouteService.toUsers(),
        },
        {
          title: this.$t("mainNav.shiftSchedules"),
          to: RouteService.toShiftSchedules(),
        },
        {
          title: this.$t("mainNav.downtimeReasons"),
          to: RouteService.toDowntimeReasons(),
        },
        {
          title: this.$t("mainNav.rejectReasons"),
          to: RouteService.toRejectReasons(),
        },
        {
          title: this.$t("mainNav.products"),
          to: RouteService.toProducts(),
        },
        {
          title: this.$t("mainNav.productionUnits"),
          to: RouteService.toProductionUnits(),
        },
        {
          title: this.$t("mainNav.devices"),
          to: RouteService.toDevices(),
        },
        {
          title: this.$t("mainNav.alerts"),
          to: RouteService.toAlerts(),
          isHidden: !this.isAlertsEnabled,
        },
      ];
    },
    filteredNavAdminItems() {
      return this.navAdminItems.filter(item => !item.isHidden);
    },
    isAlertsEnabled() {
      return this.activeFactory?.alertCapability !== null;
    },
    sortedProductionUnits() {
      if (this.activeFactoryProductionUnits && this.activeFactoryProductionUnits.length > 0) {
        let puList = [...this.activeFactoryProductionUnits];
        puList.sort(compareName);
        return puList;
      }
      return [];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions("dashboard", ["setActiveProductionUnitId"]),
    ...mapActions("user", ["updateTheme"]),
    setActivePUId(id) {
      this.setActiveProductionUnitId(id);
      this.$router.push(RouteService.toDashboard(this.activeProductionUnitId, this.getDashboardTab(id)));
    },
    getDashboardTab(puId) {
      return this.preferences.dashboards.production_units.find(pu => pu.id === puId)?.tab ?? "timeline";
    },
    isActiveProductionUnitId(id) {
      return this.activeProductionUnitId === id && this.isDashboardUrl;
    },
    setTheme(newTheme) {
      this.$vuetify.theme.dark = newTheme === "dark" ? true : false;
      setThemeToLocalStorage(this.$vuetify.theme.dark);
      this.updateTheme(newTheme);
    },
  },
};
</script>

<style lang="scss" scoped>
// local var
$boxShadow_color: hsl(216, 91%, 4%);

// style
.wxapp-main-navigation {
  &.v-navigation-drawer {
    padding-top: var(--v-application-top);
    background: var(--color-element-layer1);

    &.theme--dark {
      box-shadow: 0px 6px 12px rgba($boxShadow_color, 0.2);
    }
    &.theme--light {
      box-shadow: 0px 1px 2px rgba($boxShadow_color, 0.3), 0px 1px 3px 1px rgba($boxShadow_color, 0.15);
    }

    // !isMobile
    @media ($wx-md-min) {
      padding-top: 7px;
    }
    @media ($wx-xl-min) {
      padding-top: 16px; // vertically align logo with main-title
    }
  }

  // manage vertical spaces within accordions
  ::v-deep .v-list-group {
    &__items {
      .v-list-item {
        margin-bottom: 4px;
      }
    }
    // last link of the last accordion
    &:last-child {
      .v-list-group__items {
        .v-list-item {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // hack Vuetify to highlight the active page.
  // this generates UX confusion when an accordion is open.
  .v-list-item--active {
    color: var(--color-primary);

    &:before {
      opacity: 0;
    }
  }
}

// Group + Active
::v-deep .v-list-group__header.v-list-item--active {
  color: var(--color-text-theme);
}

// Custom + Group + Active
::v-deep .custom-group-active .v-list-group__header.v-list-item,
::v-deep .custom-group-active .v-list-group__header.v-list-item .v-icon {
  color: var(--color-primary) !important;
}

.wxapp-logo--drawer.v-btn {
  // local vars
  $logo_iconSize: 36px;
  $logo_padding: 10px;
  // style
  height: ($logo_iconSize + $logo_padding);
  width: ($logo_iconSize + $logo_padding);
  margin-top: 3px;
  margin-bottom: 12px;
  margin-left: 3px;
  padding: $logo_padding;
  border-radius: $logo_padding;
  background: url("~@/assets/tile-plus-logo-dark.svg") center center no-repeat; // T+ logo shape is not centered
  background-size: $logo_iconSize;

  &.theme--light {
    background-image: url("~@/assets/tile-plus-logo-light.svg");
  }

  &:not(:hover):not(:focus)::before {
    opacity: 0;
  }
}

/*
 * These 2 elements are for designer’s QA.
 * It should NEVER be visible on PROD server
 */
#themeToggleFab {
  left: 12px;
  transition: var(--smooth-transition);
}
#styleguide-breakpoint-label {
  display: block;
  position: absolute;
  left: 8px;
  bottom: 56px;
  height: 2rem;
  max-width: 2.5rem;
  font-style: normal;
  color: var(--color-text-subtle-theme);
  border-radius: var(--border-radius-sm);
  transition: var(--smooth-transition);
  &:hover {
    color: var(--color-text-theme);
    cursor: help;
  }
  &:before,
  &:after {
    position: absolute;
    padding-left: 0.5rem;
    min-width: 2rem;
    text-align: center;
    line-height: 1;
  }
  // responsive
  &:before {
    top: -5px;
    font-size: 24px;
    content: "\2194";
  }
  &:after {
    top: 14px;
    font-size: 14px;
    font-weight: bold;
    content: "XS";
    @media ($wx-sm-min) {
      content: "SM";
    }
    @media ($wx-md-min) {
      content: "MD";
    }
    @media ($wx-lg-min) {
      content: "LG";
    }
    @media ($wx-xl-min) {
      content: "XL";
    }
  }
}
</style>
