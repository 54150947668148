import Helpers from "@/helpers";
import TimeUtils from "@/components/TimeUtils";

export function toCompletionPercent(plannedQuantity, productionRunQuantity, rejectQuantity) {
    if (!plannedQuantity) {
        return {
            completionPercent: null,
            plannedQuantity: null,
            netQuantity: null,
            remainingQuantity: null,
        };
    }

    const derivedQuantities = calculateDerivedQuantities(plannedQuantity, productionRunQuantity, rejectQuantity);
    const netQuantity = derivedQuantities.netQuantity;
    const remainingQuantity = derivedQuantities.remainingQuantity;
    const currentPlannedQuantity = plannedQuantity ? plannedQuantity : 0;
    
    let completionPercent;
    if (currentPlannedQuantity && currentPlannedQuantity > 0) {
        completionPercent = Helpers.round(100 * (netQuantity / plannedQuantity), 1);
    } else {
        completionPercent = null;
    }

    return {
        completionPercent: completionPercent,
        plannedQuantity: plannedQuantity,
        netQuantity: netQuantity,
        remainingQuantity: remainingQuantity,
    };
};

export function toTimeToCompletion(plannedQuantity, productionRunQuantity, rejectQuantity, timeDistribution) {
    if (!plannedQuantity) {
        return {
            completionTime: null,
            productionSpeedPerHour: null,
        };
    }

    const derivedQuantities = calculateDerivedQuantities(plannedQuantity, productionRunQuantity, rejectQuantity);
    const netQuantity = derivedQuantities.netQuantity;
    const remainingQuantity = derivedQuantities.remainingQuantity;

    let timeToCompletion = null;
    let productionSpeedPerHour = null;
    if (canCalculateCompletionTime(plannedQuantity, netQuantity, timeDistribution)) {

        if (remainingQuantity <= 0) {
            timeToCompletion = TimeUtils.durationText(null);
        } else {
            const totalProductionSeconds = timeDistribution.total_production_time / 1000.0;
            productionSpeedPerHour = netQuantity / (totalProductionSeconds / (3600.0));
            const totalTimeInHours = remainingQuantity / productionSpeedPerHour;
            const totalTimeInSeconds = totalTimeInHours * 3600.0;
            timeToCompletion = TimeUtils.durationText(totalTimeInSeconds * 1000);
        }

    }

    return {
        completionTime: timeToCompletion,
        productionSpeedPerHour: productionSpeedPerHour,
    };
};

function calculateDerivedQuantities(plannedQuantity, productionRunQuantity, rejectQuantity) {
    const netQuantity = productionRunQuantity ? productionRunQuantity - (rejectQuantity ?? 0) : 0;
    const currentPlannedQuantity = plannedQuantity ? plannedQuantity : 0;

    let remainingQuantity;
    if (Helpers.isNotNullOrUndefined(currentPlannedQuantity) && Helpers.isNotNullOrUndefined(netQuantity)) {
        const remaining = Helpers.round(currentPlannedQuantity - netQuantity, 0);
        remainingQuantity = Math.max(remaining, 0);
    } else {
        remainingQuantity = null;
    }

    return {
        netQuantity: netQuantity,
        remainingQuantity: remainingQuantity,
    };
};

function canCalculateCompletionTime(plannedQuantity, netQuantity, timeDistribution) {
    return (
        plannedQuantity !== null &&
        plannedQuantity > 0 &&
        Helpers.isNotNullOrUndefined(timeDistribution) &&
        Helpers.isNotNullOrUndefined(timeDistribution.total_production_time) &&
        netQuantity > 0
    );
};