export default {
  namespaced: true,

  state: {
    isMenuOpen: false,
    selectedTileIndex: null,
  },

  getters: {
    isMenuOpen(state) {
      return state.isMenuOpen;
    },
    selectedTileIndex(state) {
      return state.selectedTileIndex;
    },
  },

  actions: {
    setIsMenuOpen({ commit }, value) {
      commit("setIsMenuOpen", value);
    },
    setSelectedTileIndex({ commit }, value) {
      commit("setSelectedTileIndex", value);
    },
  },

  mutations: {
    setIsMenuOpen(state, value) {
      state.isMenuOpen = value;
    },
    setSelectedTileIndex(state, value) {
      state.selectedTileIndex = value;
    },
  },
};
