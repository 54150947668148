//user flows and custom policies
export const b2cPolicies = {
  names: {
    signIn: process.env.VUE_APP_B2C_POLICY_SIGN_IN,
  },
  authorities: {
    signIn: {
      authority: process.env.VUE_APP_B2C_AUTHORITY_BASE_URL + "/" + process.env.VUE_APP_B2C_POLICY_SIGN_IN,
    },
  },
  authorityDomain: process.env.VUE_APP_B2C_AUTHORITY_DOMAIN,
};
