import FactoryService from "@/components/factory/FactoryService";
import ErrorHandling from "@/components/ErrorHandling";
import i18n from "@/i18n";
import UserService from "@/components/user/UserService";
import DemoService from "@/components/DemoService";

import { version } from "@/../package.json";
import * as SortUtils from "@/components/SortUtils";

export const OPERATOR_ROLE = "operator";
export const SUPERVISOR_ROLE = "supervisor";
export const DIRECTOR_ROLE = "director";
export const PRESENTER_ROLE = "presenter";

export default {
  namespaced: true,

  state: {
    accountId: "",
    userId: "",
    email: "",
    username: "",
    role: "",
    isAdmin: false,
    isFinancialAdmin: false,
    isTilelyticsEnabled: false,
    presenterKey: "",

    account: null,

    factories: [],

    language: "en",
    theme: "dark",
    accessible: false,
    preferences: null,
    authorizedPuIds: [],

    applicationVersion: version,
    worximityAdminAllowedCoverageInMonths: 6,
  },

  getters: {
    applicationVersion(state) {
      return state.applicationVersion;
    },
    accountId(state) {
      return state.accountId;
    },
    userId(state) {
      return state.userId;
    },
    email(state) {
      return state.email;
    },
    username(state) {
      return state.username;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isLoggedInUserFinancialAdmin(state) {
      return state.isFinancialAdmin;
    },
    isLoggedIn(state) {
      return state.userId !== "";
    },
    loggedInUserRole(state) {
      return state.role;
    },
    isOperator(state) {
      return state.role === OPERATOR_ROLE;
    },
    isSupervisor(state) {
      return state.role === SUPERVISOR_ROLE;
    },
    isDirector(state) {
      return state.role === DIRECTOR_ROLE;
    },
    isPresenter(state) {
      return state.role === PRESENTER_ROLE;
    },
    isTilelyticsEnabled(state) {
      return state.isTilelyticsEnabled;
    },
    hasOverviewAccess(state) {
      return state.role === PRESENTER_ROLE || state.role === SUPERVISOR_ROLE || state.role === DIRECTOR_ROLE;
    },
    isWorximityAdmin(state) {
      return String(state.email)
        .toLowerCase()
        .startsWith("wxadmin@");
    },
    worximityAdminAllowedCoverageInMonths(state) {
      return state.worximityAdminAllowedCoverageInMonths;
    },
    factories(state) {
      return state.factories;
    },
    isMultipleFactories(state) {
      return state.factories && state.factories.length > 1;
    },
    language(state) {
      return state.language;
    },
    isSpanish(state) {
      return state.language === "es";
    },
    isSimplifiedChinese(state) {
      return state.language === "zh";
    },
    theme(state) {
      return state.theme;
    },
    isAccessible(state) {
      return state.accessible;
    },
    preferences(state) {
      return state.preferences;
    },
    availableProductionUnits(state) {
      let productionUnits = [];
      state.factories.forEach((factory) => {
        const puWithFactoryName = factory.productionUnits.map((pu) => ({
          id: pu.id,
          name: pu.name,
          factoryId: factory.id,
          factory: factory.name,
          packages: pu.packages,
          puGroupId: pu.productionUnitGroupId,
        }));
        productionUnits.push(...puWithFactoryName);
      });
      return productionUnits;
    },
    authorizedPuIds(state) {
      return state.authorizedPuIds;
    },
    presenterKey(state) {
      return state.presenterKey;
    },
    account(state) {
      return state.account;
    },
  },

  actions: {
    fetchUserAccount({ commit }, accountId) {
      if (!accountId || accountId === "") return;
      return UserService.getAccount(accountId)
        .then((response) => {
          if (response.status === 200) {
            const account = response.data;
            commit("setAccount", account);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
    fetchUserFactories({ commit, getters }) {
      function getObjective(objectives, objectiveType) {
        return objectives.find((o) => o.type === objectiveType);
      }

      return FactoryService.getFactoriesForUser()
        .then((response) => {
          if (response.status === 200) {
            const factories = response.data.map((f, index) => ({
              id: f.id,
              name: DemoService.maskFactoryNameIfNecessary(getters.email, f.name, index + 1),
              timezone: f.timezone,
              primaryLanguage: f.primary_language,
              yearFirstMonth: f.year_first_month ? f.year_first_month : "january",
              weekFirstDay: f.week_first_day ? f.week_first_day : "monday",
              currency: f.currency ? f.currency : "CAD",
              productionUnits: f.production_units.map((pu, puIndex) => ({
                id: pu.id,
                name: DemoService.maskProductionUnitNameIfNecessary(getters.email, pu.name, puIndex + 1),
                productionUnitGroupId: pu.production_unit_group_id,
                downtimeJustificationDelay: pu.downtime_justification_delay_in_seconds,
                convertedUnitName: pu.converted_unit_name,
                packages: pu.packages,
                availabilityTarget: getObjective(pu.objectives, "availability")?.value,
                performanceTarget: getObjective(pu.objectives, "performance")?.value,
                qualityTarget: getObjective(pu.objectives, "quality")?.value,
                oeeTarget: getObjective(pu.objectives, "oee")?.value,
                ooeTarget: getObjective(pu.objectives, "ooe")?.value,
                isNetQuantityEnabled: pu.is_net_quantity_enabled,
              })),
              factoryObjectives: f.objectives.map((obj) => ({
                value: obj.value,
                objective: obj.objective_type,
              })),
              productionDayMinutesFromMidnight: f.production_day_minutes_from_midnight,
              isCalendarDayBusinessDay: f.is_calendar_day_business_day,
              alertCapability: f.alert_capability,
            }));
            commit("setFactories", factories);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
    updateLanguage({ commit, getters }, newLanguage) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.language = newLanguage;
              preferencesDB.language = newLanguage;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateTheme({ commit, getters }, newTheme) {
      if (getters.userId) {
        // The module `theme` values are restricted to 'dark' and 'light' only.
        // If the user selected the `Accessible` theme, translate it to `light` with the flag `accessible` set to true.
        // NOTE: it's the module Javascript object `preferences` we set that way, not the `preferencesDB`!!
        const theme = newTheme === "accessible" ? "light" : newTheme;
        const accessible = newTheme === "accessible";

        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              // `preferences` is the (vuex) store object
              let preferences = getters.preferences;
              preferences.theme = theme;
              preferences.accessible = accessible;
              commit("setPreferences", preferences);

              // `preferencesDB` is the payload sent to the backend service and persisted to the database.
              let preferencesDB = response.data;
              preferencesDB.theme = newTheme;
              preferencesDB.accessible = accessible;
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedProductionUnitId({ commit, getters }, newSelectedProductionUnitId) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.dashboards.selected_production_unit_id = newSelectedProductionUnitId;
              preferencesDB.dashboards.selected_production_unit_id = newSelectedProductionUnitId;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateActiveProductionUnitTileSelection({ commit, getters }, { index, name, config }) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              const preferencesDB = response.data;
              const preferences = UserService.updateActiveProductionUnitTileSelection(
                preferencesDB,
                index,
                name,
                config,
                getters.authorizedPuIds,
              );
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferences);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedGraph({ commit, getters }, { newSelectedGraph, kpiConfig }) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              const updatedPreferences = UserService.updateActiveProductionUnitGraphSelection(newSelectedGraph, kpiConfig);
              preferencesDB.dashboards = JSON.parse(JSON.stringify(updatedPreferences)).dashboards;
              commit("setPreferences", updatedPreferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedOverviewKpi({ getters, commit }, { kpiName, config }) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              const preferencesDB = response.data;
              const preferences = UserService.updateOverviewKpiSelection(
                preferencesDB,
                kpiName,
                config,
                getters.authorizedPuIds,
              );
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferences);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateProductionUnitDashboardTab({ getters, rootGetters, commit }, newSelectedTab) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;

              const activeProductionUnitId = rootGetters["dashboard/activeProductionUnitId"];
              const productionUnitDashboardPreferences = preferences.dashboards.production_units.find(
                (pu) => pu.id === activeProductionUnitId,
              );
              productionUnitDashboardPreferences.tab = newSelectedTab;
              preferencesDB.dashboards = JSON.parse(JSON.stringify(preferences)).dashboards;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateSelectedPeriod({ getters, commit }, newSelectedPeriod) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;
              preferences.selected_period = newSelectedPeriod;
              preferencesDB.selected_period = newSelectedPeriod;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateProductionUnitSamplingType({ getters, rootGetters, commit }, newSelectedSamplingType) {
      if (getters.userId) {
        UserService.getUserPreferences(getters.authorizedPuIds)
          .then((response) => {
            if (response.status === 200) {
              let preferencesDB = response.data;
              let preferences = getters.preferences;

              const activeProductionUnitId = rootGetters["dashboard/activeProductionUnitId"];
              const productionUnitDashboardPreferences = preferences.dashboards.production_units.find(
                (pu) => pu.id === activeProductionUnitId,
              );
              productionUnitDashboardPreferences.sampling = newSelectedSamplingType;
              preferencesDB.dashboards = JSON.parse(JSON.stringify(preferences)).dashboards;
              commit("setPreferences", preferences);
              UserService.updatePreferences(preferencesDB);
            }
          })
          .catch((error) => {
            commit(
              "operation/showOperationError",
              ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
              { root: true },
            );
          });
      }
    },
    updateProductionUnit({ state, getters, commit, dispatch }, updatedPU) {
      function getObjective(objectives, objectiveType) {
        return objectives.find((o) => o.objective_type === objectiveType);
      }

      const factory = state.factories.find((f) => f.id === updatedPU.factory_id);
      let indexOfOldFactory = state.factories.indexOf(factory);
      let newPUList = [...factory.productionUnits];
      let indexOfOldPU = newPUList.findIndex((pu) => pu.id === updatedPU.id);
      newPUList.splice(indexOfOldPU, 1);

      let newProductionUnit = {
        id: updatedPU.id,
        name: DemoService.maskProductionUnitNameIfNecessary(getters.email, updatedPU.name, indexOfOldPU + 1),
        productionUnitGroupId: updatedPU.production_unit_group_id,
        downtimeJustificationDelay: updatedPU.downtime_justification_delay_in_seconds,
        convertedUnitName: updatedPU.converted_unit_name,
        packages: updatedPU.packages,
        availabilityTarget: getObjective(updatedPU.objectives, "availability")?.value,
        performanceTarget: getObjective(updatedPU.objectives, "performance")?.value,
        qualityTarget: getObjective(updatedPU.objectives, "quality")?.value,
        oeeTarget: getObjective(updatedPU.objectives, "oee")?.value,
        ooeTarget: getObjective(updatedPU.objectives, "ooe")?.value,
        isNetQuantityEnabled: updatedPU.is_net_quantity_enabled,
      };
      newPUList.push(newProductionUnit);
      newPUList.sort(SortUtils.compareName);

      factory.productionUnits = newPUList;

      const factories = state.factories;
      // modify in place the new pu list, since the rest of the factory doesn't change
      factories[indexOfOldFactory].productionUnits = newPUList;
      commit("setFactories", factories);

      dispatch("navigation/updateFactory", factory, { root: true });
    },
  },

  mutations: {
    setUser(state, user) {
      state.userId = user.userId;
      state.email = user.email;
      state.username = user.username;
      state.role = user.role;
      state.isAdmin = user.isAdmin;
      state.isFinancialAdmin = user.isFinancialAdmin;
      state.accountId = user.accountId;
      state.presenterKey = user.presenterKey;
      state.authorizedPuIds = user.authorizedPuIds;
    },
    clearUser(state) {
      state.userId = "";
      state.email = "";
      state.username = "";
      state.role = "";
      state.isAdmin = false;
      state.isFinancialAdmin = false;
      state.factories = [];
      state.accountId = "";
      state.presenterKey = "";
      state.authorizedPuIds = [];
    },
    setFactories(state, factories) {
      state.factories = factories;
    },
    setPreferences(state, preferences) {
      state.preferences = preferences;
      state.language = preferences.language;

      if (preferences.theme === "accessible" || preferences.accessible) {
        state.theme = "light";
        state.accessible = true;
      } else {
        state.theme = preferences.theme;
        state.accessible = false;
      }
    },
    setTilelyticsEnabled(state, isEnabled) {
      state.isTilelyticsEnabled = isEnabled;
    },
    setAccount(state, account) {
      state.account = account;
    },
  },
};
