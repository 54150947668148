import http from "../../BaseServiceHttp";

class FactoryService {
  getFactoriesForUser() {
    return http.get(`/user/factories`);
  }

  updateFactory(factoryId, httpData) {
    return http.put(`/factories/${factoryId}`, httpData);
  }

  getFactoryTags(factoryId) {
    return http.get(`/factories/${factoryId}/tags`);
  }
}

export default new FactoryService();
