class PackageFeatures {
  availability = "availability";
  performance = "performance";
  oee = "oee";
  productQuantity = "product_quantity";
  reject = "reject";
  giveaway = "giveaway";
}

export default new PackageFeatures();
