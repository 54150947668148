import http from "../../BaseServiceHttp";
import axios from "axios";
import { getTokenRedirect } from "@/authentication/authRedirect";
import { tokenRequest } from "@/authentication/authConfig";

class ProductService {
  getProduct(productId, includeDeleted) {
    return http.get(`/products/${productId}?includeDeleted=${includeDeleted}`);
  }
  getProducts(factoryId) {
    return http.get(`/factories/${factoryId}/products`);
  }
  createProduct(factoryId, newProduct) {
    return http.post(`/factories/${factoryId}/products`, newProduct);
  }
  updateProduct(updatedProduct) {
    return http.put(`/products/${updatedProduct.id}`, updatedProduct);
  }
  deleteProduct(productId) {
    return http.delete(`/products/${productId}`);
  }
  uploadProducts(factoryId, file) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post(`/factories/${factoryId}/products/import`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }
  downloadProducts(factoryId, forProductionUnitId, searchKeyword) {
    const http = axios.create({
      baseURL: process.env.VUE_APP_DASHBOARD_API_BASE_URL,
      method: "POST",
      responseType: "blob",
    });
    http.interceptors.request.use(async (req) => {
      const token = await getTokenRedirect(tokenRequest);
      req.headers.Authorization = "Bearer " + token;
      if (req.data) req.headers["Content-Type"] = "application/json";
      return req;
    });

    let queryParameters = [];
    if (forProductionUnitId && forProductionUnitId.length > 0) {
      queryParameters.push(`production_unit_id=${forProductionUnitId}`);
    }
    if (searchKeyword && searchKeyword.length > 0) {
      queryParameters.push(`sku_contains=${searchKeyword}`);
      queryParameters.push(`description_contains=${searchKeyword}`);
      queryParameters.push(`category_contains=${searchKeyword}`);
    }
    let queryParamString = "";
    if (queryParameters.length > 0) {
      queryParamString = "?" + queryParameters.join("&");
    }
    return http.get(`/factories/${factoryId}/products/export${queryParamString}`);
  }
  getProductionUnitProducts(productionUnitId) {
    return http.get(`production-units/${productionUnitId}/products`);
  }
}

export default new ProductService();
